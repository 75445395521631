import React, { useRef, useState, useEffect } from "react";
import CaseStudyThumbnail from "./CaseStudyThumbnail";
import contentData from "../Content.json";
import "./CaseStudy.css";

const Portfolio = ({ style, onThumbnailClick, clearMessages }) => {
  const caseStudies = contentData.portfolio.caseStudies;
  const portfolioRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleScroll = () => {
    const portfolioElement = portfolioRef.current;
    if (!portfolioElement) return;

    const { scrollLeft, scrollWidth, clientWidth } = portfolioElement;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  const scrollLeft = (event) => {
    event.preventDefault(); // Prevent default behavior
    event.stopPropagation(); // Stop the event from propagating
    portfolioRef.current.scrollBy({
      left: -300, // Adjust this value to control the scroll amount
      behavior: "smooth",
    });
  };

  const scrollRight = (event) => {
    event.preventDefault(); // Prevent default behavior
    event.stopPropagation(); // Stop the event from propagating
    portfolioRef.current.scrollBy({
      left: 300, // Adjust this value to control the scroll amount
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const portfolioElement = portfolioRef.current;
    if (portfolioElement) {
      portfolioElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (portfolioElement) {
        portfolioElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="portfolio-wrapper" style={style}>
      {showLeftArrow && (
        <button className="scroll-button left" onClick={scrollLeft}>
          &#9664; {/* Left arrow */}
        </button>
      )}
      <div className="portfolio" ref={portfolioRef}>
        {caseStudies.map((caseStudy, index) => (
          <CaseStudyThumbnail
            key={caseStudy.id}
            caseStudy={caseStudy}
            delay={index * 0.4}
            onClick={(event) => {
              event.preventDefault(); // Prevent default behavior
              event.stopPropagation(); // Stop the event from propagating
              clearMessages(); // Clear messages when thumbnail is clicked
              onThumbnailClick(caseStudy.id, caseStudy.coverImageLink);
            }}
          />
        ))}
      </div>
      {showRightArrow && (
        <button className="scroll-button right" onClick={scrollRight}>
          &#9654; {/* Right arrow */}
        </button>
      )}
    </div>
  );
};

export default Portfolio;
