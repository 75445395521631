import React from "react";

const Element = ({ element }) => {
  console.log("Rendering element type:", element.type); // Debugging log
  switch (element.type) {
    case "text":
      return (
        <div className="element-text">
          {element.title && <h3>{element.title}</h3>}
          <p>{element.content}</p>
        </div>
      );
    case "image":
      return (
        <div className="element-image">
          <img src={element.link} alt={element.title || "Image"} />
          <div className="element-image-text">
            {element.title && <h3>{element.title}</h3>}
            {element.content && <p>{element.content}</p>}
          </div>
        </div>
      );
    case "grid":
      return (
        <div className="element-grid">
          <div className="grid-container">
            {element.elements &&
              element.elements.map((item, index) => (
                <div key={index} className="grid-item">
                  {item.title && <h4>{item.title}</h4>}
                  {item.content && <p>{item.content}</p>}
                </div>
              ))}
          </div>
        </div>
      );
    case "video":
      return (
        <div>
          <div className="element-video">
            <iframe
              src={`${element.link}?autoplay=1&controls=1&showinfo=0&rel=0&modestbranding=1&start=1`}
              frameBorder="0"
              allow="encrypted-media"
              allowFullScreen
              title={element.title || "Video"}
            ></iframe>
          </div>
          <div className="element-video-text">
            {element.title && <h3>{element.title}</h3>}
            {element.content && <p>{element.content}</p>}
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default Element;
