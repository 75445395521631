import React from "react";
import PropTypes from "prop-types";
import "./CaseStudyThumbnail.css";

const CaseStudyThumbnail = ({ caseStudy, delay, onClick }) => {
  if (
    !caseStudy ||
    !caseStudy.title ||
    !caseStudy.subtitle ||
    !caseStudy.coverImageLink
  ) {
    console.error("Missing required case study data for CaseStudyThumbnail");
    return null;
  }

  return (
    <div className="case-study-thumbnail-container">
      <div
        className="case-study-thumbnail"
        style={{
          animationDelay: `${delay}s`,
          backgroundImage: `url(${caseStudy.coverImageLink})`,
        }}
        onClick={onClick}
      >
        <div className="case-study-details">
          <h3>{caseStudy.title}</h3>
          <p>{caseStudy.subtitle}</p>
        </div>
      </div>
    </div>
  );
};

CaseStudyThumbnail.propTypes = {
  caseStudy: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    coverImageLink: PropTypes.string.isRequired,
  }).isRequired,
  delay: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CaseStudyThumbnail;
