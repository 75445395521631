import React, { useEffect, useRef } from "react";
import "./Fireflies.css";

const Fireflies = ({ color }) => {
  const containerRef = useRef(null);
  const numberOfFireflies = 100;
  const fireflies = [];

  useEffect(() => {
    const container = containerRef.current;
    container.innerHTML = ""; // Clear existing fireflies before adding new ones

    for (let i = 0; i < numberOfFireflies; i++) {
      const firefly = document.createElement("div");
      firefly.className = "firefly";
      firefly.style.backgroundColor = color;
      firefly.style.boxShadow = `0 0 8px ${color}`;
      container.appendChild(firefly);
      applyRandomAnimation(firefly);
      fireflies.push(firefly);
    }

    const handleMouseMove = (event) => {
      fireflies.forEach((firefly) => {
        const rect = firefly.getBoundingClientRect();
        const dx = rect.x + rect.width / 2 - event.clientX;
        const dy = rect.y + rect.height / 2 - event.clientY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < 100) {
          const angle = Math.atan2(dy, dx);
          const moveX = Math.cos(angle) * 50;
          const moveY = Math.sin(angle) * 50;
          firefly.style.transform = `translate(${moveX}px, ${moveY}px)`;
          firefly.style.transition = "transform 0.2s ease-out";
        } else {
          firefly.style.transform = "";
          firefly.style.transition = "transform 1s ease-out";
        }
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [color]);

  function applyRandomAnimation(firefly) {
    const xStart = Math.random() * 100;
    const yStart = Math.random() * 100;
    const xEnd = Math.random() * 100;
    const yEnd = Math.random() * 100;

    const animationName = `move-${Math.random().toString(36).substr(2, 9)}`;
    const keyframes = `
      @keyframes ${animationName} {
        from { transform: translate(${xStart}vw, ${yStart}vh); }
        to { transform: translate(${xEnd}vw, ${yEnd}vh); }
      }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = keyframes;
    document.head.appendChild(styleSheet);

    firefly.style.animation = `${animationName} 10s infinite linear, blink 5s infinite ease-in-out`;
    firefly.style.animationDelay = `${Math.random() * 5}s, ${
      Math.random() * 5
    }s`;
  }

  return <div ref={containerRef} className="firefly-container" />;
};

export default Fireflies;
