import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import Fireflies from "./Fireflies";
import { createClient } from "pexels";
import Portfolio from "./components/Portfolio";
import CaseStudy from "./components/CaseStudy";
import {
  generateInitialPrompt,
  generateUserIntroductionPrompt,
} from "./utils/promptUtils";
import contentData from "./UpdatedContent.json"; // Use the updated content
import Element from "./components/Element";
import axios from "axios";

const MAX_MESSAGES = 5; // Define the maximum number of messages to keep for context

const AvatarSelection = ({ onSelect }) => {
  const avatars = [
    "🙋🏻‍♀️",
    "🙋",
    "🙋🏻‍♂️",
    "🤖",
    "🦸‍♂️",
    "🦸‍♀️",
    "🧙‍♂️",
    "🧙‍♀️",
    "👽",
    "🧛‍♂️",
    "🧛‍♀️",
    "🧜‍♂️",
    "🧜‍♀️",
    "👨‍🚀",
  ];

  return (
    <div className="avatar-selection">
      <div className="avatar-options">
        <hr className="hrLine" />
        <h3 className="greetingSubHeadline">Select your avatar to start</h3>

        {avatars.map((avatar, index) => (
          <button
            key={index}
            onClick={() => onSelect(avatar)}
            className="avatar-button"
          >
            {avatar}
          </button>
        ))}
      </div>
    </div>
  );
};

const App = () => {
  const [backgroundMedia, setBackgroundMedia] = useState([]);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [greeting, setGreeting] = useState("");
  const [city, setCity] = useState("");
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [weather, setWeather] = useState({});
  const [promptCount, setPromptCount] = useState(0);
  const [useVideoBackground, setUseVideoBackground] = useState(true);
  const [showQuestions, setShowQuestions] = useState(false);
  const [portfolioStyle, setPortfolioStyle] = useState({ display: "none" });
  const [visibleBlocks, setVisibleBlocks] = useState([]);
  const [preSelectedQuestions, setPreSelectedQuestions] = useState(
    contentData.portfolio.preSelectedQuestions
  );
  const [currentKnowledge, setCurrentKnowledge] = useState(
    contentData.portfolio.knowledge
  );
  const [initialResponse, setInitialResponse] = useState(true); // Track initial response
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const sessionId = useRef(`session-${Date.now()}`);

  const chatEndRef = useRef(null);
  const inputGroupRef = useRef(null);
  const dropdownRef = useRef(null);
  const caseStudyRef = useRef(null);

  const client = createClient(process.env.REACT_APP_PEXELS_API_KEY);

  useEffect(() => {
    // Fetch location data using ipinfo.io API
    fetch(`https://ipinfo.io/json?token=39efe9d46deca6`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Geolocation data:", data);
        setCity(data.city);
        fetchBackgroundMedia(`downtown ${data.city}`);
        const [lat, lon] = data.loc.split(",");
        fetchWeatherData(lat, lon);

        const currentHour = new Date().getHours();
        let greetingMessage =
          currentHour < 12
            ? "Good morning"
            : currentHour < 18
            ? "Good afternoon"
            : "Good evening";
        setGreeting(greetingMessage);

        const initialPrompt = generateInitialPrompt(greetingMessage, data.city);
        sendMessageToChatGPT(initialPrompt, true); // Send initial prompt without displaying
      })
      .catch((error) => console.error("Error fetching location:", error));

    const storedMessages = localStorage.getItem("chatMessages");
    setMessages(storedMessages ? JSON.parse(storedMessages) : []);

    // Push initial state to the history stack
    window.history.pushState({ page: "app" }, "App", window.location.pathname);

    const handlePopState = (event) => {
      // If the state is the initial state, reset the chat or stay on the same page
      if (event.state && event.state.page === "app") {
        // You can reset the state or do nothing to prevent leaving the page
        console.log("Navigating to the initial state");
        // Example: setMessages([]); // Clear messages or reset the state
      } else {
        // Push back to the initial state if there is no state
        window.history.pushState(
          { page: "app" },
          "App",
          window.location.pathname
        );
      }
    };

    // Listen for the popstate event
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMediaIndex(
        (prevIndex) => (prevIndex + 1) % backgroundMedia.length
      );
    }, 10000);
    return () => clearInterval(interval);
  }, [backgroundMedia]);

  const fetchBackgroundMedia = (query) => {
    client.videos
      .search({ query, per_page: 3 })
      .then((response) => {
        if (response.videos && response.videos.length > 0) {
          const videos = response.videos.map(
            (video) => video.video_files[0].link
          );
          setBackgroundMedia(videos);
        }
      })
      .catch((error) =>
        console.error("Error fetching background videos:", error)
      );
  };

  const fetchWeatherData = (lat, lon) => {
    fetch(
      `https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&units=imperial&exclude=minutely,hourly,daily,alerts&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        setWeather({
          temp: Math.round(data.current.temp),
          description: data.current.weather[0].description,
          icon: `https://openweathermap.org/img/wn/${data.current.weather[0].icon}.png`,
        });
      })
      .catch((error) => console.error("Error fetching weather data:", error));
  };

  const sendMessageToChatGPT = async (userMessage, hidePrompt = false) => {
    console.log("messages-yo", userMessage);
    console.log("previous message", messages);
    let updatedMessages;
    if (hidePrompt) {
      updatedMessages = [
        {
          role: "user",
          content: userMessage,
          hidden: true,
          avatar: selectedAvatar,
        },
      ];
    } else {
      updatedMessages = [
        ...messages, // Keep previous messages
        { role: "user", content: userMessage, avatar: selectedAvatar },
      ];
    }
    console.log("updatedMessages", updatedMessages);
    setMessages(updatedMessages);
    localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));

    // Only send the last MAX_MESSAGES messages for context
    const textMessages = updatedMessages
      .filter((msg) => !msg.hidden)
      .slice(-MAX_MESSAGES)
      .map((msg) => {
        if (msg.knowledge) {
          return {
            role: msg.role,
            content: msg.knowledge,
          };
        } else {
          return msg;
        }
      });

    // Cleanly format the current knowledge content
    const cleanCurrentKnowledge = currentKnowledge.replace(/["{}]/g, "");
    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are a chatbot assisting users with David Lee's portfolio. First response should say 'thanks for selecting the avatar, how may I help you?' and nothing else, as all the visitors must select an avatar to start.   Your response must consist of two parts: #type: and the response content. Choose one of the following types based on the user's query:
          1. type: "portfolio" - When the user asks about portfolio, case studies, or work history. Do not show portfolio unless asked specifically.
          2. type: "text" - For regular text responses such as explaining design principles, user research, problem-solving approaches, etc.
          3. type: "image" - When sharing an image related to the portfolio.
          4. type: "video" - When sharing a video related to the portfolio.
          If they ask for portfolio, just return "#portfolio Here you go"
          Always format links as HTML in the responses. If asked for a resume, generate HTML to provide the link: http://www.davidlee.design/images/Resume.pdf with a label David Lee's Resume and open this in new tab. Here is the current context: ${cleanCurrentKnowledge},`,
        },
        ...textMessages,
      ],
      max_tokens: 4000,
    });
    console.log("textMessages", textMessages);
    console.log("requestBody", requestBody);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: requestBody,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const botResponseContent = data.choices[0].message.content;

      console.log("Raw Response from ChatGPT:", botResponseContent);

      let type;
      let content;
      const typeMatch = botResponseContent.match(/#type:\s*"?(\w+)"?|#(\w+)/);
      if (typeMatch) {
        type = typeMatch[1] || typeMatch[2];
        content = botResponseContent
          .replace(/#type:\s*"?\w+"?|#\w+/, "")
          .trim();
      } else {
        type = "text";
        content = botResponseContent;
      }

      console.log("Extracted Type:", type);
      console.log("Cleaned Content:", content);

      const botResponse = {
        role: "assistant",
        content,
        type,
      };

      console.log("Final Bot Response:", botResponse);

      const newMessages = [...updatedMessages, botResponse];

      if (botResponse.type === "portfolio") {
        setPortfolioStyle({ display: "flex" });
      }

      setMessages(newMessages);
      localStorage.setItem("chatMessages", JSON.stringify(newMessages));

      // Save conversation to backend
      await axios.post("/api/conversations/save", {
        sessionId: sessionId.current,
        userId: "guest", // Replace with actual user ID if available
        messages: newMessages,
      });

      if (
        !hidePrompt &&
        botResponse.type !== "caseStudy" &&
        promptCount === 0
      ) {
        document.body.style.transition = "background 5s ease-in-out";
        document.body.style.backgroundColor = "#000";
        setUseVideoBackground(false);
        setPromptCount((prevCount) => prevCount + 1);
      }

      // Only scroll if it's not the initial response
      if (!initialResponse && chatEndRef.current) {
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
      //  Set initialResponse to false after the first message
      setInitialResponse(false);
    } catch (error) {
      console.error("Error:", error);
      setMessages((prev) => [
        ...prev,
        { content: `Error: ${error.message}`, role: "assistant" },
      ]);
    }
  };

  const handleSendMessage = () => {
    if (!inputText.trim()) return;

    sendMessageToChatGPT(inputText);
    setInputText("");
    setShowQuestions(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleQuestionSelect = (question) => {
    setInputText(question);
    setShowQuestions(false);
  };

  const clearMessages = () => {
    setMessages([]);
  };

  useEffect(() => {
    // Custom scroll logic can be added here if needed
    // For now, the scrolling is disabled by commenting out the original scroll logic
    // if (chatEndRef.current && promptCount > 0) {
    //   chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  }, [messages, promptCount]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputGroupRef.current &&
        !inputGroupRef.current.contains(event.target)
      ) {
        setShowQuestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (portfolioStyle.display === "flex" && chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [portfolioStyle]);

  const handleThumbnailClick = (caseStudyId, coverImageLink, knowledge) => {
    const selectedCaseStudy = contentData.portfolio.caseStudies.find(
      (study) => study.id === caseStudyId
    );

    const caseStudyMessage = {
      role: "assistant",
      type: "caseStudy",
      caseStudy: selectedCaseStudy,
      coverImageLink,
      knowledge: selectedCaseStudy.knowledge,
    };

    console.log("caseStudyMessage", caseStudyMessage.knowledge);

    // Temporarily disable clearing of previous messages
    setMessages((prevMessages) => [...prevMessages, caseStudyMessage]);
    setVisibleBlocks([]); // Reset visible blocks

    setPreSelectedQuestions(selectedCaseStudy.preSelectedQuestions || []);

    console.log(
      "Setting currentKnowledge:",
      selectedCaseStudy.knowledge || contentData.portfolio.knowledge
    );
    setCurrentKnowledge(
      selectedCaseStudy.knowledge || contentData.portfolio.knowledge
    );
  };

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
  };

  return (
    <div className="home-container">
      {useVideoBackground && backgroundMedia[currentMediaIndex] && (
        <video
          className="video-background"
          src={backgroundMedia[currentMediaIndex]}
          autoPlay
          loop
          muted
        />
      )}
      <Fireflies color={"#fff"} />
      <div className="weather sticky">
        <div className="weatherTop">
          <img
            src={weather.icon}
            alt={weather.description}
            className="weatherIcon"
          />
          <span className="weatherTemp">{weather.temp}</span>
          <span className="weatherTempUnit">°F</span>
        </div>
        <span className="weatherCity">{city}</span>
      </div>
      <div className="profile-container">
        <img
          src="/images/david-producthunt.gif"
          alt="Profile"
          className="profile-pic"
        />
      </div>
      <p className="greetingMain">
        {greeting}, {city}. contact :{" "}
        <a href="mailto:me@davidlee.design">me@davidlee.design</a>
      </p>
      <h1 className="greetingHeadline">
        My name is David and this is my AI portfolio
      </h1>

      {!selectedAvatar ? (
        <AvatarSelection onSelect={handleAvatarSelect} />
      ) : (
        <div className="chat-container">
          <div className="chat-messages">
            {messages.map((msg, index) => {
              if (msg.type === "portfolio") {
                return (
                  <div key={index} className="chat-messages">
                    <div
                      className="message assistant-message"
                      style={{ display: "block" }}
                    >
                      {msg.content}
                    </div>
                    <Portfolio
                      style={portfolioStyle}
                      onThumbnailClick={handleThumbnailClick}
                      clearMessages={clearMessages}
                    />
                  </div>
                );
              } else if (msg.type === "caseStudy") {
                return (
                  <div
                    id={msg.caseStudy.id}
                    key={index}
                    className="case-study-overlay"
                    ref={caseStudyRef}
                  >
                    <CaseStudy
                      caseStudy={msg.caseStudy}
                      visibleBlocks={visibleBlocks}
                      setVisibleBlocks={setVisibleBlocks}
                    />
                  </div>
                );
              } else if (msg.type === "image" || msg.type === "video") {
                return (
                  <div
                    key={index}
                    className={`message assistant-message`}
                    style={{ display: "block" }}
                  >
                    <Element element={msg} />
                  </div>
                );
              }

              // Render messages with HTML content using dangerouslySetInnerHTML
              return (
                <div
                  key={index}
                  className={`message ${
                    msg.role === "user" ? "user-message" : "assistant-message"
                  }`}
                  style={{
                    display:
                      msg.role === "user" && msg.hidden ? "none" : "block",
                  }}
                >
                  {msg.role === "user" && msg.avatar && (
                    <span className="avatar">{msg.avatar}</span>
                  )}
                  {msg.role === "assistant" && (
                    <div className="assistant-message-container">
                      <img
                        src="/images/david-producthunt.gif"
                        alt="Assistant Avatar"
                        className="assistant-message-avatar"
                      />
                      <div
                        className="assistant-message-content"
                        dangerouslySetInnerHTML={{ __html: msg.content }}
                      ></div>
                    </div>
                  )}
                  {msg.role !== "assistant" && (
                    <span
                      dangerouslySetInnerHTML={{ __html: msg.content }}
                    ></span>
                  )}
                </div>
              );
            })}
            <div ref={chatEndRef} />
          </div>

          <div className="input-group sticky" ref={inputGroupRef}>
            <div className="input-field-group">
              <input
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Ask about my portfolio"
                className="input-field"
                onClick={() => setShowQuestions(true)}
              />
              <button onClick={handleSendMessage} className="send-button">
                ▴
              </button>
            </div>
            {showQuestions && (
              <div className="dropdown-menu" ref={dropdownRef}>
                {preSelectedQuestions.length > 0 ? (
                  preSelectedQuestions.map((question, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleQuestionSelect(question)}
                    >
                      {question}
                    </div>
                  ))
                ) : (
                  <div className="dropdown-item">No questions available</div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
