// src/components/ContentBlock.js
import React from "react";
import Element from "./Element";

const ContentBlock = ({ contentBlock, gridColor }) => {
  // Check if the parent content block has type "grid"
  if (contentBlock.type === "grid") {
    return (
      <div className="element-grid">
        <div className="grid-container">
          {contentBlock.elements.map((element, index) => (
            <div
              key={index}
              className="grid-item"
              style={{ backgroundColor: gridColor }} // Apply gridColor
            >
              <Element element={element} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  // Default rendering for other types
  return (
    <div className="content-block-inner">
      {contentBlock.elements.map((element, index) => (
        <React.Fragment key={index}>
          <Element element={element} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ContentBlock;
