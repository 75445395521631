// src/utils/promptUtils.js

export const generateInitialPrompt = (greeting, city) => {
  return `just say hello`;
};

export const generateUserIntroductionPrompt = (name, profession, location) => {
  return `You are ${name}, a ${profession} from ${location}. Your job is to guide me through your portfolio and answer any questions. The answers and everything you must know will be here. You will also guide me with a story. Start with "My name is ${name} (you've already said hi before). Tell me your job, and tell me that I can ask questions or select preselected questions to begin. Enjoy your time."`;
};

export const generateProjectDetailsPrompt = (projectName) => {
  return `Tell me more about the project named ${projectName}.`;
};

export const showMeYourWork = (projectName) => {
  return `Show me your ${projectName}.`;
};

// Add more prompt generation functions as needed
