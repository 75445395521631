import React, { useEffect, useRef } from "react";
import ContentBlock from "./ContentBlock";
import "./CaseStudy.css";

const CaseStudy = ({ caseStudy, visibleBlocks, setVisibleBlocks }) => {
  const contentBlockRefs = useRef([]);

  const handleContinueClick = (index) => {
    setVisibleBlocks((prevVisibleBlocks) => {
      const nextIndex = index + 1;
      if (nextIndex < caseStudy.contentBlocks.length) {
        setTimeout(() => {
          if (contentBlockRefs.current[nextIndex]) {
            contentBlockRefs.current[nextIndex].scrollIntoView({
              behavior: "smooth",
              block: "start",
            });

            // Adjust the scroll position after scrollIntoView
            setTimeout(() => {
              const yOffset = -100; // Adjust this value as needed
              const elementPosition =
                contentBlockRefs.current[nextIndex].getBoundingClientRect().top;
              const offsetPosition =
                elementPosition + window.pageYOffset + yOffset;

              console.log("Scrolling to:", offsetPosition); // Debugging log
              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
              });
            }, 500); // Ensure this delay is slightly longer than the smooth scroll duration
          }
        }, 500);
        return [...prevVisibleBlocks, nextIndex];
      }
      return prevVisibleBlocks;
    });
  };

  useEffect(() => {}, [visibleBlocks]);

  return (
    <div
      className="case-study"
      style={{
        backgroundColor: caseStudy.backgroundColor || "#fff",
        color: caseStudy.textColor || "#000",
        fontFamily: caseStudy.textFont || "sans-serif",
      }}
    >
      <div className="logo-container">
        <img src={caseStudy.logo} alt="Profile" className="logo-pic" />
        <h1>{caseStudy.title}</h1>
        <p className="CTA_description">{caseStudy.description}</p>
        <div className="CTA_Group">
          <a
            className="continue-button"
            style={{
              backgroundColor: caseStudy.buttonColor || "#ECECEC", // Default button color
              color: caseStudy.textColor || "#000", // Default text color
            }}
            onClick={() => handleContinueClick(-1)}
          >
            📺 Read case study
          </a>
          <a
            className="continue-button"
            href={caseStudy.liveLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: caseStudy.buttonColor || "#ECECEC", // Default button color
              color: caseStudy.textColor || "#000", // Default text color
            }}
          >
            📡 View live
          </a>
        </div>
      </div>
      <div className="content-blocks">
        {caseStudy.contentBlocks.map((contentBlock, index) => {
          return (
            <div
              key={contentBlock.id}
              ref={(el) => (contentBlockRefs.current[index] = el)}
              data-index={index}
              className="content-block"
              style={{
                display: visibleBlocks.includes(index) ? "flex" : "none",
              }}
            >
              {contentBlock.title && <h2>{contentBlock.title}</h2>}
              {visibleBlocks.includes(index) && (
                <>
                  <ContentBlock
                    contentBlock={contentBlock}
                    gridColor={caseStudy.gridColor} // Pass gridColor to ContentBlock
                  />
                  {index < caseStudy.contentBlocks.length - 1 && (
                    <button
                      className="continue-button"
                      onClick={() => handleContinueClick(index)}
                      style={{
                        backgroundColor: caseStudy.buttonColor || "#ECECEC", // Default button color
                        color: caseStudy.textColor || "#000", // Default text color
                      }}
                    >
                      Continue
                    </button>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CaseStudy;
